// import Form from 'react-bootstrap/Form';
const TickIcon = ()=> {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
        className='tick'
        width="50" height="50" 
        viewBox="0 0 512 512">
        <title>ionicons-v5-e</title>
        <path d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z"/>
        <polyline points="352 176 217.6 336 160 272"/>
        </svg>

    
    )
}
export default TickIcon